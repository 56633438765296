export class Detailed {

  init(stats_util_class_ref) {
    this.fight_stats = null;
    this.fight_stat_mappings = null;

    this.StatsUtil = stats_util_class_ref;

    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_red_plus_id, this.onRedPlusClick);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_blue_plus_id, this.onBluePlusClick);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_expand_ss_id, this.onExpandSSClick);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_expand_ts_id, this.onExpandTSClick);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_expand_ct_id, this.onExpandCTClick);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_matrix_btn_id, this.showDetailedMatrix);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_rbr_btn_id, this.showDetailedRbr);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.detailed_advanced_stats_btn_id, this.showDetailedAdvancedStats);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.notif_new_btn_id, this.toggleNotificationForm);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.notif_post_btn_id, this.postNotification);
    $(html_ref.shared.current_view_id).on('click', html_ref.detailed.notif_clear_btn_id, this.StatsUtil.clearNotification);
    $(html_ref.shared.current_view_id).on('keydown', html_ref.detailed.notif_form_text_id, this.onNotificationTextAreaKeydown);
  };

  static renderDetailed() {
    $(html_ref.shared.current_view_id).data('current-view', 'detailed');
    $(html_ref.shared.current_view_id).addClass('detailed-body');
    $(html_ref.shared.body_inner_id).addClass('detailed-parent');
    $('.' + html_ref.shared.view_btn_class).removeClass('active-font');
    $(html_ref.shared.detailed_btn_id).addClass('active-font');
    $(html_ref.shared.current_view_id).load('/screens/render_detailed', {}, function(response, status, xhr) {
      if (status == 'error') {
        toastr.error('Unable to load detailed view.');
      } else {
        $(html_ref.shared.current_view_id).removeClass('main-body history-body insights-body');
        $(html_ref.shared.body_inner_id).removeClass('main-parent history-parent insights-parent');
        detailed.fight_stats = null;
        detailed.fight_stat_mappings = detailed.StatsUtil.statMappings('detailed');
        detailed.StatsUtil.clearDetailedView();
        detailed.StatsUtil.loadFighterNames();
        detailed.StatsUtil.getFightMatchupStats(false, detailed.injectBestStats);

        $(html_ref.detailed.rbr_autocomplete_id).autocomplete(
          { 
            source: detailed.buildAutoCompleteArray(),
            classes: {'ui-autocomplete': 'ui-autocomplete-rbr'},
            minLength: 2,
            select: function (event, ui) {
              detailed.StatsUtil.clearDetailedRbrData();
              $(html_ref.detailed.rbr_loading_id).show();
              $(html_ref.detailed.rbr_block_id).data('picked-stat', ui['item']['data'][0]);
              $(html_ref.detailed.rbr_block_id).data('picked-stat-unit', ui['item']['data'][1]);
            }
          } 
        );
      }
    });
  };

  static injectFightStats(data) {
    if (detailed.fight_stat_mappings && detailed.StatsUtil.isDetailedViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId'])) && ($(html_ref.shared.current_view_id).data('picked-round') == String(data['Round'])) && ($('#sidebar').data('api-source') == data['Source'])) {      
      // Insert official flag if available
      detailed.StatsUtil.insertFightStatus(data, false);

      // Inject left / matrix stats
      for (const [key, val] of Object.entries(detailed.fight_stat_mappings)) {
        $('.red-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Red']) + val['unit']);
        $('.blue-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Blue']) + val['unit']);
      }

      // Inject rbr stats
      detailed.injectRbrStats(data);

      // Show
      detailed.StatsUtil.showDetailedView();

      detailed.fight_stats = data;
    }
  };

  static injectFightOdds(data) {
    if (detailed.StatsUtil.isDetailedViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId']))) {
      $('.red-live-dk-closing-odds').text((data['Odds']['Closing']['Red'] > 0) ? ('+' + String(data['Odds']['Closing']['Red'])) : data['Odds']['Closing']['Red']);
      $('.red-live-dk-live-odds').text((data['Odds']['Live']['Red'] > 0) ? ('+' + String(data['Odds']['Live']['Red'])) : data['Odds']['Live']['Red']);
      $('.red-live-dk-bet-percentage').text((data['Odds']['ClosingBetPercentage']['Red'] != null) ? (String(data['Odds']['ClosingBetPercentage']['Red']) + '%') : '');
      $('.red-live-dk-handle-percentage').text((data['Odds']['ClosingHandlePercentage']['Red'] != null) ? (String(data['Odds']['ClosingHandlePercentage']['Red']) + '%') : '');
      $('.blue-live-dk-closing-odds').text((data['Odds']['Closing']['Blue'] > 0) ? ('+' + String(data['Odds']['Closing']['Blue'])) : data['Odds']['Closing']['Blue']);
      $('.blue-live-dk-live-odds').text((data['Odds']['Live']['Blue'] > 0) ? ('+' + String(data['Odds']['Live']['Blue'])) : data['Odds']['Live']['Blue']);
      $('.blue-live-dk-bet-percentage').text((data['Odds']['ClosingBetPercentage']['Blue'] != null) ? (String(data['Odds']['ClosingBetPercentage']['Blue']) + '%') : '');
      $('.blue-live-dk-handle-percentage').text((data['Odds']['ClosingHandlePercentage']['Blue'] != null) ? (String(data['Odds']['ClosingHandlePercentage']['Blue']) + '%') : '');
    }
  };

  injectRbrStats(data) {
    if (data['RbrData'] && (data['RbrData']['Stat'] == $(html_ref.detailed.rbr_block_id).data('picked-stat')) && (data['RbrData']['Rounds'].length > 0)) {
      $(html_ref.detailed.rbr_loading_id).hide();

      let rbr_stats_array = [], i;

      // Numeric values

      for (i = 0 ; i < data['RbrData']['Rounds'].length ; i++) {
        $('#red-rbr-' + String(i+1)).html(data['RbrData']['Rounds'][i]['Red'] + $(html_ref.detailed.rbr_block_id).data('picked-stat-unit'));
        $('#blue-rbr-' + String(i+1)).html(data['RbrData']['Rounds'][i]['Blue'] + $(html_ref.detailed.rbr_block_id).data('picked-stat-unit'));
        if ((typeof(data['RbrData']['Rounds'][i]['Red']) == 'string') && (data['RbrData']['Rounds'][i]['Red'].indexOf(':') != -1)) {
          rbr_stats_array.push(detailed.StatsUtil.mmssToInt(data['RbrData']['Rounds'][i]['Red']));
          rbr_stats_array.push(detailed.StatsUtil.mmssToInt(data['RbrData']['Rounds'][i]['Blue']));
        } else {
          rbr_stats_array.push(data['RbrData']['Rounds'][i]['Red']);
          rbr_stats_array.push(data['RbrData']['Rounds'][i]['Blue']);
        }
      }

      // Bar charts

      let d3_scale = d3.scaleLinear().domain([0, Math.max.apply(null, rbr_stats_array)]).range([0, 190]);
      let count = 1, count2 = 1;

      for(i = 0 ; i < rbr_stats_array.length ; i += 2) {
        if ((rbr_stats_array[i] > 0) || (rbr_stats_array[i+1] > 0)) {
          let round_chart = d3.select('#chart-rbr-' + String(count));
          let bars = round_chart.selectAll('div');
          let bars_update = bars.data([rbr_stats_array[i], rbr_stats_array[i+1]]);

          bars_update.enter().append('div').merge(bars_update)
          .style('width', function(d) { return d3_scale(d) + 'px'; })
          .style('height', '12px')
          .style('margin-bottom', '4px')
          .style('background-color', function(d) {
            if (count2 % 2 === 0) {
              count2++;
              return '#0b81d1';
            } else {
              count2++;
              return '#cc0000';
            }
          });
        }

        count++;
      }
    }
  };

  injectBestStats(data) {
    if (detailed.StatsUtil.isDetailedViewLoaded() && ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(data['FightId']))) {
      for (const [key, val] of Object.entries(detailed.StatsUtil.statMappings('matchup'))) {
        if (data[val['data'][0]][val['data'][1]]['Red'] !== '') {
          $('.red-matchup-best-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Red']) + val['unit']);
        } else {
          $('.red-matchup-best-' + String(key)).text('');
        }
        if (data[val['data'][0]][val['data'][1]]['Blue'] !== '') {
          $('.blue-matchup-best-' + String(key)).text(String(data[val['data'][0]][val['data'][1]]['Blue']) + val['unit']);
        } else {
          $('.blue-matchup-best-' + String(key)).text('');
        }
      }
    }
  };

  // Stats sections control

  onExpandSSClick() {
    $(html_ref.detailed.detailed_expand_ss_id).text('-' + $(html_ref.detailed.detailed_expand_ss_id).text().substr(1));
    $(html_ref.detailed.detailed_expand_ts_id).text('+' + $(html_ref.detailed.detailed_expand_ts_id).text().substr(1));
    $(html_ref.detailed.detailed_expand_ct_id).text('+' + $(html_ref.detailed.detailed_expand_ct_id).text().substr(1));
    $(html_ref.detailed.detailed_ss_section_id).css('display','block');
    $(html_ref.detailed.detailed_ts_section_id).css('display','none');
    $(html_ref.detailed.detailed_ct_section_id).css('display','none');
  };

  onExpandTSClick() {
    $(html_ref.detailed.detailed_expand_ts_id).text('-' + $(html_ref.detailed.detailed_expand_ts_id).text().substr(1));
    $(html_ref.detailed.detailed_expand_ss_id).text('+' + $(html_ref.detailed.detailed_expand_ss_id).text().substr(1));
    $(html_ref.detailed.detailed_expand_ct_id).text('+' + $(html_ref.detailed.detailed_expand_ct_id).text().substr(1));
    $(html_ref.detailed.detailed_ss_section_id).css('display','none');
    $(html_ref.detailed.detailed_ts_section_id).css('display','block');
    $(html_ref.detailed.detailed_ct_section_id).css('display','none');
  };

  onExpandCTClick() {
    $(html_ref.detailed.detailed_expand_ct_id).text('-' + $(html_ref.detailed.detailed_expand_ct_id).text().substr(1));
    $(html_ref.detailed.detailed_expand_ss_id).text('+' + $(html_ref.detailed.detailed_expand_ss_id).text().substr(1));
    $(html_ref.detailed.detailed_expand_ts_id).text('+' + $(html_ref.detailed.detailed_expand_ts_id).text().substr(1));
    $(html_ref.detailed.detailed_ss_section_id).css('display','none');
    $(html_ref.detailed.detailed_ts_section_id).css('display','none');
    $(html_ref.detailed.detailed_ct_section_id).css('display','block');
  };

  // Personal best control

  onRedPlusClick() {
    detailed.toggleBestSectionVisibility('red');    
  };

  onBluePlusClick() {
    detailed.toggleBestSectionVisibility('blue');
  };

  toggleBestSectionVisibility(color) {
    if (!detailed.isBestSectionVisible(color)) {
      $(html_ref.detailed.detailed_body_left_id + ' #detailed-' + color + '-plus').text('-');
      $(html_ref.detailed.detailed_body_left_id + ' .detailed-' + color + '-best').css('display','flex');
      $(html_ref.detailed.detailed_body_left_id + ' .resizable').removeClass('col-md-4').addClass('col-md-3');
      if ((color == 'red') && detailed.isBestSectionVisible('blue')) { detailed.toggleBestSectionVisibility('blue'); }
      if ((color == 'blue') && detailed.isBestSectionVisible('red')) { detailed.toggleBestSectionVisibility('red'); }
    } else {
      $(html_ref.detailed.detailed_body_left_id + ' #detailed-' + color + '-plus').text('+');
      $(html_ref.detailed.detailed_body_left_id + ' .detailed-' + color + '-best').css('display','none');
      if (!detailed.isBestSectionVisible('red') && !detailed.isBestSectionVisible('blue')) {
        $(html_ref.detailed.detailed_body_left_id + ' .resizable').removeClass('col-md-3').addClass('col-md-4');
      }
    }
  };

  isBestSectionVisible(color) {
    return ($('.detailed-' + color + '-best').first().css('display') != 'none');
  };

  // Matrix / rbr section control

  showDetailedMatrix() {
    $(html_ref.detailed.detailed_matrix_btn_id).addClass('active-font').removeClass('lightgray-font');
    $(html_ref.detailed.detailed_rbr_btn_id).removeClass('active-font').addClass('lightgray-font');
    $(html_ref.detailed.detailed_advanced_stats_btn_id).removeClass('active-font').addClass('lightgray-font');
    $(html_ref.detailed.matrix_block_margin_id).show();
    $(html_ref.detailed.rbr_block_id).hide();
    $(html_ref.detailed.advanced_stats_block_id).hide();
  };

  showDetailedRbr() {
    $(html_ref.detailed.detailed_rbr_btn_id).addClass('active-font').removeClass('lightgray-font');
    $(html_ref.detailed.detailed_matrix_btn_id).removeClass('active-font').addClass('lightgray-font');
    $(html_ref.detailed.detailed_advanced_stats_btn_id).removeClass('active-font').addClass('lightgray-font');
    $(html_ref.detailed.rbr_block_id).show();
    $(html_ref.detailed.matrix_block_margin_id).hide();
    $(html_ref.detailed.advanced_stats_block_id).hide();
  };

  showDetailedAdvancedStats() {
    $(html_ref.detailed.detailed_advanced_stats_btn_id).addClass('active-font').removeClass('lightgray-font');
    $(html_ref.detailed.detailed_rbr_btn_id).removeClass('active-font').addClass('lightgray-font');
    $(html_ref.detailed.detailed_matrix_btn_id).removeClass('active-font').addClass('lightgray-font');
    $(html_ref.detailed.advanced_stats_block_id).show();
    $(html_ref.detailed.rbr_block_id).hide();
    $(html_ref.detailed.matrix_block_margin_id).hide();
  };

  buildAutoCompleteArray() {
    let array = []
    for (const [key, val] of Object.entries(detailed.StatsUtil.statMappings('detailed'))) {
      array.push({value: val['rbrname'], data: [val['data'][1], val['unit']]})
    }
    return array;
  };

  // Notification control

  toggleNotificationForm() {
    if ($(html_ref.detailed.notif_new_btn_text_id).text() == 'NEW') {
      $(html_ref.detailed.notif_new_btn_text_id).text('CANCEL');
      $(html_ref.detailed.notif_form_text_id).val('');
      $(html_ref.detailed.notif_new_btn_plus_sign_id).text('');
      $(html_ref.detailed.notif_form_holder_id).show();
      $(html_ref.detailed.notif_holder_id).hide();
    } else {
      $(html_ref.detailed.notif_new_btn_text_id).text('NEW');
      $(html_ref.detailed.notif_new_btn_plus_sign_id).text('+');
      $(html_ref.detailed.notif_holder_id).show();
      $(html_ref.detailed.notif_form_holder_id).hide();
    }
  };

  postNotification() {
    if ($(html_ref.detailed.notif_form_text_id).val() != '') {
      $.ajax({
        url: '/notifications/ajax_post_notification',
        method: 'POST',
        data: {
          text: $(html_ref.detailed.notif_form_text_id).val()
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data) {
          if (data['success']) {
            detailed.toggleNotificationForm();
          } else {
            toastr.error('Notification NOT sent. Please try again.');
          }
        },
        error: function(data) {
          toastr.error('Notification NOT sent. Please try again.');
        }
      });
    }
  };

  onNotificationTextAreaKeydown(e) {
    let charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode == 13) && !e.shiftKey) {
      e.preventDefault();
      detailed.postNotification();
    };
  };
}

export let detailed = new Detailed();