export class StatsUtil {

  static getFightStats(data_callback, success_callback, error_callback) {
    if ($(html_ref.shared.current_view_id).data('picked-event-id') && $(html_ref.shared.current_view_id).data('picked-fight-id')) {
      $.ajax({
        url: '/screens/ajax_fight_live_stats',
        method: 'GET',
        timeout: 3000,
        data: {
          source: $('#sidebar').data('api-source'),
          round: $(html_ref.shared.current_view_id).data('picked-round'),
          event_id: $(html_ref.shared.current_view_id).data('picked-event-id'), 
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id'),
          picked_rbr_stat: StatsUtil.isDetailedViewLoaded() ? $(html_ref.detailed.rbr_block_id).data('picked-stat') : null
        },
        success: function(data) {
          StatsUtil.activateRounds(data['FightId'], data['AvailableRounds'], true);
          data_callback(data);
          success_callback();
        },
        error: function(data) {
          StatsUtil.showCurrentViewLoader();
          error_callback();
        }
      });
    } else {
      success_callback();
    }
  };

  static getFightOdds(data_callback, success_callback, error_callback) {
    if ($(html_ref.shared.current_view_id).data('picked-event-id') && $(html_ref.shared.current_view_id).data('picked-fight-id')) {
      $.ajax({
        url: '/screens/ajax_fight_live_odds',
        method: 'GET',
        timeout: 3000,
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id')
        },
        success: function(data) {
          data_callback(data);
          success_callback();
        },
        error: function(data) {
          error_callback();
        }
      });
    } else {
      success_callback();
    }
  };

  static getFightInsights(data_callback, success_callback, error_callback) {
    if ($(html_ref.shared.current_view_id).data('picked-event-id') && $(html_ref.shared.current_view_id).data('picked-fight-id')) {
      $.ajax({
        url: '/screens/ajax_fight_live_insights',
        method: 'GET',
        timeout: 3000,
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id'),
          sort_by: $(html_ref.insights.insights_controls_sort_by_checked).val(),
          filter_corners: StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_corner_checked),
          filter_types: StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_type_checked),
          filter_vars: StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_var_checked),
          filter_times: StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_time_checked),
          controls_string: StatsUtil.insightsControlsStringRepresentation()
        },
        success: function(data) {
          data_callback(data);
          success_callback();
        },
        error: function(data) {
          StatsUtil.showCurrentViewLoader();
          error_callback();
        }
      });
    } else {
      success_callback();
    }
  };

  static getFightMatchupStats(add_ufc_wc, data_callback) {
    if ($(html_ref.shared.current_view_id).data('picked-fight-id')) {
      $.ajax({
        url: '/screens/ajax_fight_matchup_stats',
        method: 'GET',
        data: {
          fight_id: $(html_ref.shared.current_view_id).data('picked-fight-id'),
          wc_id: $(html_ref.shared.current_view_id).data('picked-fight-wc-id'),
          red_fighter_id: $(html_ref.shared.current_view_id).data('picked-fight-red-fighter-id'),
          blue_fighter_id: $(html_ref.shared.current_view_id).data('picked-fight-blue-fighter-id'),
          add_ufc_wc: add_ufc_wc
        },
        success: function(data) {
          data_callback(data);
        },
        error: function(data) {
          toastr.error('Unable to load matchup stats. Please refresh to try again.');
        }
      });
    }
  };

  static activateRounds(fight_id, available_rounds, also_rbr) {
    if ((fight_id == null) || ($(html_ref.shared.current_view_id).data('picked-fight-id') == String(fight_id))) {
      for (let i = 1 ; i <= 5 ; i++) {
        if (i <= available_rounds) {
          $('#header-round' + String(i)).not('.yellow-bg').removeClass('darkgray-font').addClass('lightgray-font hover');
          if (also_rbr) { $('#detailed-rbr-round-' + String(i)).show(); }
        } else {
          $('#header-round' + String(i)).removeClass('lightgray-font hover').addClass('darkgray-font');
          if (also_rbr) { $('#detailed-rbr-round-' + String(i)).hide(); }
        }
      }
    }
  };

  static insertFightStatus(data, with_official_results) {
    if (data['Official']) {
      if (with_official_results) {
        $(html_ref.shared.fight_status_holder_id).html('OFFICIAL | ' + ((data['Round'] == 0) ? '<b>TOTAL STATS</b>' : '<b>ROUND ' + String(data['Round']) + ' STATS</b>') + ' <br> ' + data['Result']['Winner'] + ' by ' + data['Result']['Method'] + ' <br> RD ' + data['Result']['EndingRound'] + ' - ' + data['Result']['EndingTime']);
      } else {
        $(html_ref.shared.fight_status_holder_id).html('OFFICIAL | ' + ((data['Round'] == 0) ? '<b>TOTAL STATS</b>' : '<b>ROUND ' + String(data['Round']) + ' STATS</b>'));
      }
    } else {
      $(html_ref.shared.fight_status_holder_id).html(((data['Round'] == 0) ? 'TOTAL STATS' : '<b>ROUND ' + String(data['Round']) + ' STATS</b>'));
      if ((data['Round'] == 0) && data['CurrentRoundDisplay']) {
        $(html_ref.shared.fight_clock_holder_id).html('&nbsp;&nbsp;/&nbsp;&nbsp;' + data['CurrentRoundDisplay']);
      } else {
        $(html_ref.shared.fight_clock_holder_id).html('');
      }
    }

    if (data['Round'] == 0) {
      $(html_ref.shared.fight_status_holder_id).addClass('yellow-font');
      $(html_ref.shared.fight_status_holder_parent_id).removeClass('yellow-bg');
    } else {
      $(html_ref.shared.fight_status_holder_id).removeClass('yellow-font');
      $(html_ref.shared.fight_status_holder_parent_id).addClass('yellow-bg');
    }
  };

  static loadFighterNames() {
    if ($(html_ref.shared.current_view_id).data('picked-fight-id')) {
      $('.' + html_ref.shared.red_firstname_class).text($(html_ref.sidebar.picked_fight_red_firstname_id).val());
      $('.' + html_ref.shared.red_lastname_class).text($(html_ref.sidebar.picked_fight_red_lastname_id).val());
      $('.' + html_ref.shared.blue_firstname_class).text($(html_ref.sidebar.picked_fight_blue_firstname_id).val());
      $('.' + html_ref.shared.blue_lastname_class).text($(html_ref.sidebar.picked_fight_blue_lastname_id).val());
    }
  };

  static loadWCAbbr() {
    if ($(html_ref.shared.current_view_id).data('picked-fight-id')) {
      $('.' + html_ref.shared.wc_abbr_class).text($(html_ref.sidebar.picked_fight_wc_abbr_id).val());
    }
  };

  // App section differentiation functions

  static inStatsView() {
    return ($('.stats').length && ($('.stats').length > 0));
  }

  static inTalentCuesView() {
    return ($('.talent_cues').length && ($('.talent_cues').length > 0));
  }

  // Screen visibility functions

  static isMainViewLoaded() {
    return ($(html_ref.shared.current_view_id).data('current-view') == 'main');
  };

  static clearMainViewStats() {
    StatsUtil.showMainViewStatsLoader();
    $(html_ref.shared.fight_status_holder_id).html('');
    $('.' + html_ref.shared.red_blue_of_class).hide();    
    for (const [key, val] of Object.entries(StatsUtil.statMappings('main'))) {
      $('.red-' + String(key)).text('');
      $('.blue-' + String(key)).text('');
    }
  };

  static darkenMainViewStats() {
    $(html_ref.main.main_body_left_stats_holder_id).css('opacity', '40%');
  };

  static lightenMainViewStats() {
    $(html_ref.main.main_body_left_stats_holder_id).css('opacity', '100%');
  };

  static showMainViewStatsLoader() {
    $(html_ref.main.main_body_left_stats_holder_id).css('opacity', '40%');
    $(html_ref.main.main_body_left_overlay_spinner_id).addClass('spinner');    
  };

  static showMainViewStats() {
    $('.' + html_ref.shared.red_blue_of_class).show();
    $(html_ref.main.main_body_left_overlay_spinner_id).removeClass('spinner');
    if (!$(html_ref.main.notif_holder_id).is(':visible')) {
      $(html_ref.main.main_body_left_stats_holder_id).css('opacity', '100%');
    }
  };

  static clearMainViewMatchupStats() {
    $(html_ref.main.main_body_right_stats_holder_id).css('opacity', '40%');
    $(html_ref.main.main_body_right_overlay_spinner_id).addClass('spinner');    
  };

  static showMainViewMatchupStats() {
    $(html_ref.main.main_body_right_overlay_spinner_id).removeClass('spinner');
    $(html_ref.main.main_body_right_stats_holder_id).css('opacity', '100%');
  };

  static clearMainView() {
    StatsUtil.clearMainViewStats();
    StatsUtil.clearMainViewMatchupStats();
  };

  static isDetailedViewLoaded() {
    return ($(html_ref.shared.current_view_id).data('current-view') == 'detailed');
  };

  static clearDetailedView() {
    StatsUtil.showDetailedViewLoader();
    $(html_ref.shared.fight_status_holder_id).text('');
    $(html_ref.shared.fight_clock_holder_id).text('');
    $('.' + html_ref.shared.red_blue_of_class).hide();
    for (const [key, val] of Object.entries(StatsUtil.statMappings('detailed'))) {
      $('.red-' + String(key)).text('');
      $('.blue-' + String(key)).text('');
    }
    StatsUtil.clearDetailedRbrData();
  };

  static clearDetailedRbrData() {
    for (let i = 1 ; i <=5 ; i++) {
      $('#red-rbr-' + String(i)).html('&nbsp;');
      $('#blue-rbr-' + String(i)).html('&nbsp;');
      $('#chart-rbr-' + String(i)).html('');
    }
  };

  static showDetailedViewLoader() {
    $(html_ref.detailed.detailed_body_left_id).css('opacity', '40%');
    $(html_ref.detailed.detailed_body_right_id).css('opacity', '40%');
    $(html_ref.detailed.detailed_body_overlay_spinner_id).addClass('spinner'); 
  };

  static showDetailedView() {
    $('.' + html_ref.shared.red_blue_of_class).show();
    $(html_ref.detailed.detailed_body_overlay_spinner_id).removeClass('spinner');
    $(html_ref.detailed.detailed_body_left_id).css('opacity', '100%');
    $(html_ref.detailed.detailed_body_right_id).css('opacity', '100%');
  };

  static isHistoryViewLoaded() {
    return ($(html_ref.shared.current_view_id).data('current-view') == 'history');
  };

  static clearHistoryView() {
    StatsUtil.showHistoryViewLoader();
    $(html_ref.shared.fight_status_holder_id).text('');
    $(html_ref.shared.fight_clock_holder_id).text('');
    for (const [key, val] of Object.entries(StatsUtil.statMappings('matchup'))) {
      $('.red-' + String(key)).text('');
      $('.blue-' + String(key)).text('');
    }
  };

  static showHistoryViewLoader() {
    $(html_ref.history.history_body_left_id).css('opacity', '40%');
    $(html_ref.history.history_body_right_id).css('opacity', '40%');
    $(html_ref.history.history_body_overlay_spinner_id).addClass('spinner');
  };

  static showHistoryView() {
    $(html_ref.history.history_body_overlay_spinner_id).removeClass('spinner');
    $(html_ref.history.history_body_left_id).css('opacity', '100%');
    $(html_ref.history.history_body_right_id).css('opacity', '100%');
  };

  static isInsightsViewLoaded() {
    return ($(html_ref.shared.current_view_id).data('current-view') == 'insights');
  };

  static clearInsightsView() {
    StatsUtil.showInsightsViewLoader();
  };

  static showInsightsViewLoader() {
    $(html_ref.insights.insights_body_id).css('opacity', '40%');
    $(html_ref.insights.insights_body_overlay_spinner_id).addClass('spinner');
  };

  static showInsightsView() {
    $(html_ref.insights.insights_body_overlay_spinner_id).removeClass('spinner');
    $(html_ref.insights.insights_body_id).css('opacity', '100%');
  };

  static clearCurrentView() {
    if (StatsUtil.isMainViewLoaded()) {
      StatsUtil.clearMainViewStats();
    } else if (StatsUtil.isDetailedViewLoaded()) {
      StatsUtil.clearDetailedView();
    } else if (StatsUtil.isHistoryViewLoaded()) {
      StatsUtil.clearHistoryView();
    }
  };

  static showCurrentViewLoader() {
    if (StatsUtil.isMainViewLoaded()) {
      StatsUtil.showMainViewStatsLoader();
    } else if (StatsUtil.isDetailedViewLoaded()) {
      StatsUtil.showDetailedViewLoader();
    } else if (StatsUtil.isHistoryViewLoaded()) {
      StatsUtil.showHistoryViewLoader();
    } else if (StatsUtil.isInsightsViewLoaded()) {
      StatsUtil.showInsightsViewLoader();
    }
  };

  // Notifications

  static clearNotification() {
    $.ajax({
      url: '/notifications/ajax_clear_notification',
      method: 'POST',
      data: {},
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function(data) {
        if (!data['success']) {
          toastr.error('Notification NOT cleared. Please try again.');
        }
      },
      error: function(data) {
        toastr.error('Notification NOT cleared. Please try again.');
      }
    });
  };

  // Time util

  static mmssToInt(time) {
    let time_array = time.split(':');
    if (time_array.length == 2) {
      return ((parseInt(time_array[0]) * 60) + parseInt(time_array[1]));
    } else {
      return 0;
    }
  };

  // Misc util

  // Returns array checked checkbox values from checkboxes that share the same class
  static checkboxGroupValueArray(checkbox_group_class) {
    return $(checkbox_group_class).map(function() { return this.value; }).get();
  };

  static insightsControlsStringRepresentation() {
    let sort_by = $(html_ref.insights.insights_controls_sort_by_checked).val();
    let filter_corner = StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_corner_checked).join('_');
    let filter_type = StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_type_checked).join('_');
    let filter_var = StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_var_checked).join('_');
    let filter_time = StatsUtil.checkboxGroupValueArray(html_ref.insights.insights_controls_filter_time_checked).join('_');
    return `${sort_by}_${filter_corner}_${filter_type}_${filter_var}_${filter_time}`;
  };

  static capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Mappings

  static mainStatMappings() {
    return {
      kdl: { data: ['Stats', 'Knockdowns'], medname: 'KNOCKDOWNS', rbrname: 'Knockdowns', unit: '' },

      tsl: { data: ['Stats','TotalStrikesLanded'], medname: 'TOTAL STRIKES', rbrname: 'Total Strikes - Landed', unit: '' },
      tsa: { data: ['Stats','TotalStrikesAttempted'], medname: 'STRIKES ATTEMPTED', rbrname: 'Total Strikes - Attempted', unit: '' },
      tsac: { data: ['Stats','TotalStrikesAccuracy'], medname: 'STRIKING ACCURACY', rbrname: 'Total Strikes - Accuracy', unit: '%' },
      
      htsl: { data: ['Stats','TotalHeadStrikesLanded'], medname: 'HEAD STRIKES', rbrname: 'Head Strikes - Landed', unit: '' },
      htsa: { data: ['Stats','TotalHeadStrikesAttempted'], medname: 'HEAD STRIKES ATT.', rbrname: 'Head Strikes - Attempted', unit: '' },
      htsac: { data: ['Stats','TotalHeadStrikesAccuracy'], medname: 'HEAD STRIKING ACC.', rbrname: 'Head Strikes - Accuracy', unit: '%' },
      
      btsl: { data: ['Stats','TotalBodyStrikesLanded'], medname: 'BODY STRIKES', rbrname: 'Body Strikes - Landed', unit: '' },
      btsa: { data: ['Stats','TotalBodyStrikesAttempted'], medname: 'BODY STRIKE ATT.', rbrname: 'Body Strikes - Attempted', unit: '' },
      btsac: { data: ['Stats','TotalBodyStrikesAccuracy'], medname: 'BODY STRIKING ACC.', rbrname: 'Body Strikes - Accuracy', unit: '%' },
      
      ltsl: { data: ['Stats','TotalLegStrikesLanded'], medname: 'LEG STRIKES', rbrname: 'Leg Strikes - Landed', unit: '' },
      ltsa: { data: ['Stats','TotalLegStrikesAttempted'], medname: 'LEG STRIKES ATT.', rbrname: 'Leg Strikes - Attempted', unit: '' },
      ltsac: { data: ['Stats','TotalLegStrikesAccuracy'], medname: 'LEG STRIKE ACC.', rbrname: 'Leg Strikes - Accuracy', unit: '%' },

      dsl: { data: ['Stats','SigDistanceStrikesLanded'], medname: 'DISTANCE STRIKES', rbrname: 'Distance Strikes - Landed', unit: '' },
      dsa: { data: ['Stats','SigDistanceStrikesAttempted'], medname: 'DIST. STRIKE ATT.', rbrname: 'Distance Strikes - Attempted', unit: '' },
      dsac: { data: ['Stats','SigDistanceStrikesAccuracy'], medname: 'DIST. STRIKING ACC.', rbrname: 'Distance Strikes - Accuracy', unit: '%' },

      ctsl: { data: ['Stats','TotalClinchStrikesLanded'], medname: 'CLINCH STRIKES', rbrname: 'Clinch Strikes - Landed', unit: '' },
      ctsa: { data: ['Stats','TotalClinchStrikesAttempted'], medname: 'CLINCH STRIKE ATT.', rbrname: 'Clinch Strikes - Attempted', unit: '' },
      ctsac: { data: ['Stats','TotalClinchStrikesAccuracy'], medname: 'CLINCH STRIKES ACC.', rbrname: 'Clinch Strikes - Accuracy', unit: '%' },

      gtsl: { data: ['Stats','TotalGroundStrikesLanded'], medname: 'GROUND STRIKES', rbrname: 'Ground Strikes - Landed', unit: '' },
      gtsa: { data: ['Stats','TotalGroundStrikesAttempted'], medname: 'GROUND STRIKE ATT.', rbrname: 'Ground Strikes - Attempted', unit: '' },
      gtsac: { data: ['Stats','TotalGroundStrikesAccuracy'], medname: 'GROUND STRIKING ACC.', rbrname: 'Ground Strikes - Accuracy', unit: '%' },

      ssl: { data: ['Stats','SigStrikesLanded'], medname: 'SIGNIFICANT STRIKES', rbrname: 'Significant Strikes - Landed', unit: '' },
      ssa: { data: ['Stats','SigStrikesAttempted'], medname: 'SIG. STR. ATTEMPTED', rbrname: 'Significant Strikes - Attempted', unit: '' },
      ssac: { data: ['Stats','SigStrikesAccuracy'], medname: 'SIG. STR. ACCURACY', rbrname: 'Significant Strikes - Accuracy', unit: '%' },

      tdl: { data: ['Stats','TakedownsLanded'], medname: 'TAKEDOWNS', rbrname: 'Takedowns - Landed', unit: '' },
      tda: { data: ['Stats','TakedownsAttempted'], medname: 'TD ATTEMPTED', rbrname: 'Takedowns - Attempted', unit: '' },
      tdac: { data: ['Stats','TakedownsAccuracy'], medname: 'TD ACCURACY', rbrname: 'Takedowns - Accuracy', unit: '%' },

      suba: { data: ['Stats','SubmissionsAttempted'], medname: 'SUBMISSION ATT.', rbrname: 'Submission Attempts', unit: '' },

      tip_gct: { data: ['Stats','GroundControlTime'], medname: 'GROUND CONTROL', rbrname: 'Ground Control', unit: '' }
    }
  };

  static matchupStatMappings() {
    return {
      // Avg
      aft: { data: ['Avg','AverageFightTime'], unit: '' },
      kdavg: { data: ['Avg','KnockdownsAverage'], unit: '' },
      slpm: { data: ['Avg','SLpM'], unit: '' },        
      sapm: { data: ['Avg','SApM'], unit: '' },
      ssac: { data: ['Avg','SigStrikesAccuracy'], unit: '%' },
      ssdef: { data: ['Avg','SigStrikesDefense'], unit: '%' },
      tdavg: { data: ['Avg','TakedownsAverage'], unit: '' },
      tdac: { data: ['Avg','TakedownsAccuracy'], unit: '%' },
      tddef: { data: ['Avg','TakedownsDefense'], unit: '%' },
      subavg: { data: ['Avg','SubmissionsAverage'], unit: '' },

      // Best
      ssl: { data: ['Best','SigStrikesLanded'], unit: '' },
      hssl: { data: ['Best', 'SigHeadStrikesLanded'], unit: '' },
      bssl: { data: ['Best', 'SigBodyStrikesLanded'], unit: '' },
      lssl: { data: ['Best', 'SigLegStrikesLanded'], unit: '' },
      dsl: { data: ['Best','SigDistanceStrikesLanded'], unit: '' },
      cssl: { data: ['Best', 'SigClinchStrikesLanded'], unit: '' },
      gssl: { data: ['Best', 'SigGroundStrikesLanded'], unit: '' },
      dhsl: { data: ['Best', 'SigDistanceHeadStrikesLanded'], unit: '' },
      dlsl: { data: ['Best', 'SigDistanceLegStrikesLanded'], unit: '' },
      tsl: { data: ['Best','TotalStrikesLanded'], unit: '' },
      htsl: { data: ['Best','TotalHeadStrikesLanded'], unit: '' },
      btsl: { data: ['Best','TotalBodyStrikesLanded'], unit: '' },
      ltsl: { data: ['Best','TotalLegStrikesLanded'], unit: '' },
      ctsl: { data: ['Best','TotalClinchStrikesLanded'], unit: '' },
      gtsl: { data: ['Best','TotalGroundStrikesLanded'], unit: '' },
      kdl: { data: ['Best', 'Knockdowns'], unit: '' },
      tdl: { data: ['Best','TakedownsLanded'], unit: '' },
      tdd: { data: ['Best','TakedownsDefended'], unit: '' },
      tdall: { data: ['Best','TakedownsAllowed'], unit: '' },
      rvl: { data: ['Best', 'Reversals'], unit: '' },
      suba: { data: ['Best','SubmissionsAttempted'], unit: '' },
      tip_ct: { data: ['Best','ControlTime'], unit: '' },
      tip_ctall: { data: ['Best','ControlTimeAllowed'], unit: '' },
      tip_gct: { data: ['Best','GroundControlTime'], unit: '' },
      tip_gctall: { data: ['Best','GroundControlTimeAllowed'], unit: '' },
      ssab: { data: ['Best','SigStrikesAbsorbed'], unit: '' },
      tsab: { data: ['Best','TotalStrikesAbsorbed'], unit: '' }
    }
  }

  static detailedStatMappings() {
    return {
      hssl: { data: ['Stats', 'SigHeadStrikesLanded'], medname: 'SIG. HEAD STRIKES', rbrname: 'Significant Head Strikes - Landed', unit: '' },
      hssa: { data: ['Stats', 'SigHeadStrikesAttempted'], medname: 'SIG. HEAD STR. ATT.', rbrname: 'Significant Head Strikes - Attempted', unit: '' },
      hssac: { data: ['Stats', 'SigHeadStrikesAccuracy'], medname: 'SIG. HEAD STR. ACC.', rbrname: 'Significant Head Strikes - Accuracy', unit: '%' },

      bssl: { data: ['Stats', 'SigBodyStrikesLanded'], medname: 'SIG. BODY STRIKES', rbrname: 'Significant Body Strikes - Landed', unit: '' },
      bssa: { data: ['Stats', 'SigBodyStrikesAttempted'], medname: 'SIG. BODY STR. ATT.', rbrname: 'Significant Body Strikes - Attempted', unit: '' },
      bssac: { data: ['Stats', 'SigBodyStrikesAccuracy'], medname: 'SIG. BODY STR. ACC.', rbrname: 'Significant Body Strikes - Accuracy', unit: '%' },

      lssl: { data: ['Stats', 'SigLegStrikesLanded'], medname: 'SIG. LEG STRIKES', rbrname: 'Significant Leg Strikes - Landed', unit: '' },
      lssa: { data: ['Stats', 'SigLegStrikesAttempted'], medname: 'SIG. LEG STR. ATT.', rbrname: 'Significant Leg Strikes - Attempted', unit: '' },
      lssac: { data: ['Stats', 'SigLegStrikesAccuracy'], medname: 'SIG. LEG STR. ACC.', rbrname: 'Significant Leg Strikes - Accuracy', unit: '%' },

      cssl: { data: ['Stats', 'SigClinchStrikesLanded'], medname: 'SIG. CLINCH STR.', rbrname: 'Significant Clinch Strikes - Landed', unit: '' },
      cssa: { data: ['Stats', 'SigClinchStrikesAttempted'], medname: 'SIG. CLINCH STR. ATT.', rbrname: 'Significant Clinch Strikes - Attempted', unit: '' },
      cssac: { data: ['Stats', 'SigClinchStrikesAccuracy'], medname: 'SIG. CLINCH STR. ACC.', rbrname: 'Significant Clinch Strikes - Accuracy', unit: '%' },

      gssl: { data: ['Stats', 'SigGroundStrikesLanded'], medname: 'SIG. GROUND STR.', rbrname: 'Significant Ground Strikes - Landed', unit: '' },
      gssa: { data: ['Stats', 'SigGroundStrikesAttempted'], medname: 'SIG. GRND. STR. ATT.', rbrname: 'Significant Ground Strikes - Attempted', unit: '' },
      gssac: { data: ['Stats', 'SigGroundStrikesAccuracy'], medname: 'SIG. GRND. STR. ACC.', rbrname: 'Significant Ground Strikes - Accuracy', unit: '%'},

      sdl: { data: ['Stats', 'Standups'], medname: 'STANDUPS', rbrname: 'Standups', unit: '' },
      rvl: { data: ['Stats', 'Reversals'], medname: 'REVERSALS', rbrname: 'Reversals', unit: '' },

      tip_st: { data: ['Stats', 'StandingTime'], medname: 'STANDING TIME', rbrname: 'Standing Time', unit: '' }, 
      tip_gt: { data: ['Stats', 'GroundTime'], medname: 'GROUND TIME', rbrname: 'Ground Time', unit: '' }, 
      tip_ct: { data: ['Stats', 'ControlTime'], medname: 'CONTROL TIME', rbrname: 'Control Time', unit: '' },

      tip_mgct: { data: ['Stats', 'MiscGroundControlTime'], medname: 'MISC. GRND. CTRL. TIME', rbrname: 'Misc. Ground Control Time', unit: '' },  
      tip_gd: { data: ['Stats', 'GuardControlTime'], medname: 'GUARD CTRL. TIME', rbrname: 'Guard Control Time', unit: '' },  
      tip_hgd: { data: ['Stats', 'HalfGuardControlTime'], medname: 'HALF GUARD CTRL. TIME', rbrname: 'Half Guard Control Time', unit: '' },

      tip_sd: { data: ['Stats', 'SideControlTime'], medname: 'SIDE CTRL. TIME', rbrname: 'Side Control Time', unit: '' }, 
      tip_mct: { data: ['Stats', 'MountControlTime'], medname: 'MOUNT TIME', rbrname: 'Mount Control Time', unit: '' }, 
      tip_bk: { data: ['Stats', 'BackControlTime'], medname: 'BACK CTRL. TIME', rbrname: 'Back Control Time', unit: '' },
      tip_cct: { data: ['Stats', 'ClinchControlTime'], medname: 'CLINCH CTRL. TIME', rbrname: 'Clinch Control Time', unit: '' },

      dhsl: { data: ['Stats', 'SigDistanceHeadStrikesLanded'], medname: 'DISTANCE HEAD STR.', rbrname: 'Distance Head Strikes - Landed', unit: '' },
      dhsa: { data: ['Stats', 'SigDistanceHeadStrikesAttempted'], medname: 'DIST. HEAD STR. ATT.', rbrname: 'Distance Head Strikes - Attempted', unit: '' },
      dhsac: { data: ['Stats', 'SigDistanceHeadStrikesAccuracy'], medname: 'DIST. HEAD STR. ACC.', rbrname: 'Distance Head Strikes - Accuracy', unit: '%' },

      dbsl: { data: ['Stats', 'SigDistanceBodyStrikesLanded'], medname: 'DISTANCE BODY STR.', rbrname: 'Distance Body Strikes - Landed', unit: '' },
      dbsa: { data: ['Stats', 'SigDistanceBodyStrikesAttempted'], medname: 'DIST. BODY STR. ATT.', rbrname: 'Distance Body Strikes - Attempted', unit: '' },
      dbsac: { data: ['Stats', 'SigDistanceBodyStrikesAccuracy'], medname: 'DIST. BODY STR. ACC.', rbrname: 'Distance Body Strikes - Accuracy', unit: '%' },

      dlsl: { data: ['Stats', 'SigDistanceLegStrikesLanded'], medname: 'LEG KICKS', rbrname: 'Distance Leg Strikes - Landed', unit: '' },
      dlsa: { data: ['Stats', 'SigDistanceLegStrikesAttempted'], medname: 'LEG KICKS ATT.', rbrname: 'Distance Leg Strikes - Attempted', unit: '' },
      dlsac: { data: ['Stats', 'SigDistanceLegStrikesAccuracy'], medname: 'LEG KICKS ACC.', rbrname: 'Distance Leg Strikes - Accuracy', unit: '%' },

      chsl: { data: ['Stats', 'SigClinchHeadStrikesLanded'], medname: 'CLINCH HEAD STR.', rbrname: '', unit: '' },
      chsa: { data: ['Stats', 'SigClinchHeadStrikesAttempted'], medname: 'CLCH. HEAD STR. ATT.', rbrname: '', unit: '' },
      chsac: { data: ['Stats', 'SigClinchHeadStrikesAccuracy'], medname: 'CLCH. HEAD STR. ACC.', rbrname: '', unit: '%' }, 

      cbsl: { data: ['Stats', 'SigClinchBodyStrikesLanded'], medname: 'CLINCH BODY STR.', rbrname: '', unit: '' },
      cbsa: { data: ['Stats', 'SigClinchBodyStrikesAttempted'], medname: 'CLCH. BODY STR. ATT.', rbrname: '', unit: '' },
      cbsac: { data: ['Stats', 'SigClinchBodyStrikesAccuracy'], medname: 'CLCH. BODY STR. ACC.', rbrname: '', unit: '%' }, 

      clsl: { data: ['Stats', 'SigClinchLegStrikesLanded'], medname: 'CLINCH LEG KICKS', rbrname: '', unit: '' },
      clsa: { data: ['Stats', 'SigClinchLegStrikesAttempted'], medname: 'CLCH. LEG KICKS ATT.', rbrname: '', unit: '' },
      clsac: { data: ['Stats', 'SigClinchLegStrikesAccuracy'], medname: 'CLCH. LEG KICK ACC.', rbrname: '', unit: '%' }, 

      ghsl: { data: ['Stats', 'SigGroundHeadStrikesLanded'], medname: 'GROUND HEAD STR.', rbrname: '', unit: '' },
      ghsa: { data: ['Stats', 'SigGroundHeadStrikesAttempted'], medname: 'GRND. HEAD STR. ATT.', rbrname: '', unit: '' },
      ghsac: { data: ['Stats', 'SigGroundHeadStrikesAccuracy'], medname: 'GRND. HEAD STR. ACC.', rbrname: '', unit: '%' }, 

      gbsl: { data: ['Stats', 'SigGroundBodyStrikesLanded'], medname: 'GROUND BODY STR.', rbrname: '', unit: '' },
      gbsa: { data: ['Stats', 'SigGroundBodyStrikesAttempted'], medname: 'GRND. BODY STR. ATT.', rbrname: '', unit: '' },
      gbsac: { data: ['Stats', 'SigGroundBodyStrikesAccuracy'], medname: 'GRND. BODY STR. ACC.', rbrname: '', unit: '%' }, 

      glsl: { data: ['Stats', 'SigGroundLegStrikesLanded'], medname: 'GROUND LEG KICKS', rbrname: '', unit: '' },
      glsa: { data: ['Stats', 'SigGroundLegStrikesAttempted'], medname: 'GRND. LEG KICKS ATT.', rbrname: '', unit: '' },
      glsac: { data: ['Stats', 'SigGroundLegStrikesAccuracy'], medname: 'GRND. LEG KICKS ACC.', rbrname: '', unit: '%' },

      tsdef: { data: ['Stats', 'TotalStrikesDefense'], medname: '', rbrname: 'Total Strikes - Defense', unit: '%' },
      ssdef: { data: ['Stats', 'SigStrikesDefense'], medname: '', rbrname: 'Significant Strikes - Defense', unit: '%' },

      stsl: { data: ['Stats', 'StandingStrikesLanded'], medname: '', rbrname: 'Standing Strikes - Landed', unit: '' },
      stsa: { data: ['Stats', 'StandingStrikesAttempted'], medname: '', rbrname: 'Standing Strikes - Attempted', unit: '' },
      stsac: { data: ['Stats', 'StandingStrikesAccuracy'], medname: '', rbrname: 'Standing Strikes - Accuracy', unit: '%' },

      tdall: { data: ['Stats', 'TakedownsAllowed'], medname: '', rbrname: 'Takedowns - Allowed', unit: '%' },
      tdd: { data: ['Stats', 'TakedownsDefended'], medname: '', rbrname: 'Takedowns - Defended', unit: '' },
      tddef: { data: ['Stats', 'TakedownsDefense'], medname: '', rbrname: 'Takedowns - Defense', unit: '%' },

      // Advanced Stats

      as_esa: { data: ['Stats', 'ExpectedStrikingAccuracy'], medname: 'EXPECTED STRIKING ACCURACY', rbrname: '', unit: '%' },
      as_wad: { data: ['Stats', 'WeightedAccuracyDifferential'], medname: 'STRIKING ACCURACY DIFFERENTIAL', rbrname: '', unit: '' },
      as_tdca: { data: ['Stats', 'TakedownControlAverage'], medname: 'TAKEDOWN CONTROL AVERAGE', rbrname: '', unit: '' },
      as_gur: { data: ['Stats', 'GetUpRate'], medname: '', rbrname: 'GET UP RATE', unit: '' }
    }
  }

  static statMappings(screen) {
    if (screen == 'main') {
      return StatsUtil.mainStatMappings();
    } else if (screen == 'matchup') {
      return StatsUtil.matchupStatMappings();
    } else if (screen == 'detailed') {
      return $.extend(StatsUtil.mainStatMappings(), StatsUtil.detailedStatMappings()); 
    }
  }

}
